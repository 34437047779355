import React from 'react';
import { formatTime } from '../utils';

const CommitteeSummary = ({ summaries, onTimeSelect }) => {
  return (
    <div className="w-full flex-1 flex flex-col py-[15px] px-2.5 sm:px-5 border border-[#E5E7EB] bg-[#F9FAFB]">
      <div className="border-b border-[#E5E7EB] mb-5 pb-2.5">
        <h2
          id="Office-Information"
          className="font-playfair text-[28px] capitalize text-darkblue font-medium leading-8"
        >
          Committee Summary
        </h2>
      </div>
      <div className="flex flex-col gap-5 overflow-y-auto overflow-x-hidden h-[calc(100vh-200px)] relative">
        {summaries?.map((item, index) => (
          <div key={index} className="p-4 border border-gray-300 bg-white flex justify-between gap-10">
            <p className="text-lg text-gray-700">{item.summary}</p>
            <div className="mt-4 flex flex-col">
              <button
                onClick={() => onTimeSelect(item.startTime)}
                className="py-2 px-6 bg-[#83B2FA] text-base font-normal text-white transition-all duration-300 hover:bg-lightblue-hover w-[180px]"
              >
                Go to this section
              </button>
              <span className="text-gray-500">
                {formatTime(item.startTime / 10)} - {formatTime(item.endTime / 10)}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommitteeSummary;
