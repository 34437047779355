import React, { useState } from 'react';
import NewSearchBar from './NewSearchBar'; // Adjust the import path accordingly
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const SideMenu = ({
  companies,
  handleCompanySelect,
  billNumber,
  handleBillNumberChange,
  isBillNum,
  handleGetBillVers,
  versions,
  version,
  handleVersionChange,
  reportSections,
  selectedSections,
  handleSectionChange,
  isFormComplete,
  handleCompileReport,
  fullSum,
  handleFullSumChange,
  companyInfo,
  onDragEnd,
  selectedCompany,
  sessions, // New prop
  selectedSession, // New prop
  setSelectedSession, // New prop
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const companyRequiredSections = ['companySentiment', 'companyImpactAnalysis', 'recommendedActions'];

  const toggleSideMenu = () => {
    setIsOpen(!isOpen);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: '8px',
    margin: '0 0 4px 0',
    background: isDragging ? '#f0f0f0' : '#fff',
    border: '1px solid #ddd',
    borderRadius: '4px',
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? '#e0e0e0' : '#f7f7f7',
    padding: '8px',
    width: '100%',
    maxHeight: '400px',
    overflowY: 'auto',
  });

  return (
    <div className={`side-menu ${isOpen ? 'open' : 'closed'}`}>
      <button className="toggle-button" onClick={toggleSideMenu}>
        {isOpen ? <FaChevronLeft /> : <FaChevronRight />}
      </button>

      <div className="menu-content">
        <h2 className="menu-title">AI Report Generator</h2>

        <div className="form-group">
          <label htmlFor="company-search">Company</label>
          <NewSearchBar
            data={companies}
            fields={['RepresentationName']}
            label="Company"
            onSelect={handleCompanySelect}
          />
        </div>

        {companyInfo && (
          <div className="form-group">
            <label htmlFor="fullSum">Company Summary:</label>
            <textarea
              id="fullSum"
              value={fullSum}
              onChange={handleFullSumChange}
              placeholder="Company Full Summary"
              rows={6}
              className="textarea-field"
            />
          </div>
        )}
        <div className="form-group">
          <label htmlFor="session">Session:</label>
          <select
            id="session"
            value={selectedSession}
            onChange={(e) => setSelectedSession(e.target.value)}
            className="select-field"
          >
            <option value="">Select Session</option>
            {sessions &&
              sessions.map((sessionValue) => (
                <option key={sessionValue} value={sessionValue}>
                  {sessionValue}
                </option>
              ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="billNumber">Bill Number:</label>
          <input
            type="text"
            id="billNumber"
            value={billNumber}
            onChange={handleBillNumberChange}
            placeholder="Enter bill number"
            className="input-field"
          />
        </div>

        <button
          className={`btn get-versions-btn ${!isBillNum ? 'pulse' : ''}`}
          onClick={handleGetBillVers}
          disabled={!isBillNum}
        >
          Get Versions
        </button>

        <div className="form-group">
          <label htmlFor="version">Version:</label>
          <select
            id="version"
            value={version}
            onChange={handleVersionChange}
            disabled={!billNumber || !versions || versions.length === 0}
            className="select-field"
          >
            <option value="">Select Version</option>
            {versions &&
              versions.map((ver) => (
                <option key={ver} value={ver}>
                  {ver.toUpperCase()}
                </option>
              ))}
          </select>
        </div>

        <div className="form-group">
          <label>Select Report Sections (Drag to Reorder):</label>
          <div className="checkbox-group" style={{ border: '1px solid #ddd', borderRadius: '4px', padding: '8px' }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable-sections">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {reportSections.map((section, index) => {
                      const isSelected = selectedSections.includes(section.value);
                      const needsCompany = companyRequiredSections.includes(section.value);
                      const disabled = !selectedCompany && needsCompany;

                      return (
                        <Draggable key={section.value} draggableId={section.value} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            >
                              <div className="checkbox-item">
                                <input
                                  type="checkbox"
                                  id={section.value}
                                  value={section.value}
                                  checked={isSelected}
                                  onChange={handleSectionChange}
                                  disabled={disabled}
                                />
                                <label htmlFor={section.value}>{section.label}</label>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>

        <button className="btn compile-report-btn" onClick={handleCompileReport} disabled={!isFormComplete}>
          Compile Report
        </button>
      </div>
    </div>
  );
};

export default SideMenu;
