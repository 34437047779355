import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCompanies, getVersions, getCompanyInfo, compileReport } from '../features/bills/billSlice';
import convertMarkdownToHTML from '../components/QueryFuncs/Markdown';
import MainLoader from '../components/Structures/MainLoader';
import SideMenu from '../components/reportSideMenu';
import txLogo from '../assets/images/reportLogo.png';

const AIReport = () => {
  const dispatch = useDispatch();
  const { state } = useParams();
  const sessions = useSelector((state) => state.user.sessions);
  console.log(`State: ${state}, Sessions: ${sessions}`);
  const companies = useSelector((state) => state.bill.companies);
  const companyInfo = useSelector((state) => state.bill.companyInfo);
  const versions = useSelector((state) => state.bill.versions);
  const report = useSelector((state) => state.bill.report);
  const isLoading = useSelector((state) => state.bill.isLoading);
  const error = useSelector((state) => state.bill.error);

  const [selectedCompany, setSelectedCompany] = useState('');
  const [billNumber, setBillNumber] = useState('');
  const [version, setVersion] = useState('');
  const [fullSum, setFullSum] = useState('');
  const [selectedSession, setSelectedSession] = useState('');

  // Instead of a simple array, we now keep reportSections in state so they can be reordered
  const [reportSections, setReportSections] = useState([
    { value: 'companySentiment', label: 'Company Sentiment Towards the Bill' },
    { value: 'effectiveDate', label: 'Effective Date' },
    { value: 'areaOfEffect', label: 'Area of Effect' },
    { value: 'beneficiaries', label: 'Who Benefits and How' },
    { value: 'negativelyAffected', label: 'Who is Hurt and How' },
    { value: 'summary', label: 'Summary' },
    { value: 'keyProvisions', label: 'Key Provisions' },
    { value: 'financialImplications', label: 'Financial Implications' },
    { value: 'regulatoryImpact', label: 'Regulatory Impact' },
    { value: 'timelineAndProceduralStatus', label: 'Timeline and Procedural Status' },
    { value: 'potentialAmendments', label: 'Potential Amendments' },
    { value: 'implementationChallenges', label: 'Implementation Challenges' },
    { value: 'legalConsiderations', label: 'Legal Considerations' },
    { value: 'publicOpinionAndMediaCoverage', label: 'Public Opinion and Media Coverage' },
    { value: 'companyImpactAnalysis', label: 'Company Impact Analysis' },
    { value: 'recommendedActions', label: 'Recommended Actions' },
    { value: 'riskAssessment', label: 'Risk Assessment' },
    { value: 'complianceRequirements', label: 'Compliance Requirements' },
    { value: 'stakeholderMap', label: 'Stakeholder Map' },
    { value: 'technicalAnalysis', label: 'Technical Analysis' },
    { value: 'environmentalImpact', label: 'Environmental Impact' },
    { value: 'futureOutlook', label: 'Future Outlook' },
  ]);

  const [selectedSections, setSelectedSections] = useState([]);

  useEffect(() => {
    if (selectedCompany) {
      // If a company is selected
      setSelectedSections(['summary', 'companySentiment', 'regulatoryImpact', 'potentialAmendments', 'stakeholderMap']);
    } else {
      // If no company is selected
      setSelectedSections([
        'keyProvisions',
        'effectiveDate',
        'areaOfEffect',
        'beneficiaries',
        'negativelyAffected',
        'summary',
      ]);
    }
  }, [selectedCompany]);

  useEffect(() => {
    dispatch(getCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (companyInfo && companyInfo.FullSum) {
      setFullSum(companyInfo.FullSum);
    } else {
      setFullSum('');
    }
  }, [companyInfo]);

  const handleCompanySelect = (company) => {
    setSelectedCompany(company);
    const companyID = company.CompanyID;
    if (companyID) {
      dispatch(getCompanyInfo(companyID));
    } else {
      setFullSum('');
    }
  };

  const handleBillNumberChange = (event) => {
    setBillNumber(event.target.value);
  };

  const handleVersionChange = (event) => {
    setVersion(event.target.value);
  };

  const handleGetBillVers = () => {
    if (billNumber) {
      const billId = billNumber.replace(/\s/g, '');
      dispatch(getVersions({ billId, selectedSession }));
    }
  };

  const handleCompileReport = () => {
    // If no company selected, send empty string or null
    const companyToSend = selectedCompany || ''; // or null if you prefer

    const updatedCompanyInfo = { ...companyInfo, FullSum: fullSum || '' };
    dispatch(
      compileReport({
        company: companyToSend,
        billNumber,
        version,
        companyInfo: updatedCompanyInfo,
        selectedSections,
        state,
        selectedSession,
      })
    );
  };

  const isFormComplete = billNumber && version && selectedSections.length > 0;
  const nonNumberPattern = /\D.*\D/;
  const numberPattern = /\d/;
  const isBillNum = billNumber && nonNumberPattern.test(billNumber) && numberPattern.test(billNumber);

  const handleFullSumChange = (event) => {
    setFullSum(event.target.value);
  };

  const handleSectionChange = (event) => {
    const value = event.target.value;
    setSelectedSections((prev) => (prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]));
  };

  // Handle drag end for reordering
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newOrder = Array.from(reportSections);
    const [moved] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, moved);
    setReportSections(newOrder);
  };

  return (
    <div className="ai-report-container">
      <SideMenu
        companies={companies}
        handleCompanySelect={handleCompanySelect}
        billNumber={billNumber}
        handleBillNumberChange={handleBillNumberChange}
        isBillNum={isBillNum}
        handleGetBillVers={handleGetBillVers}
        versions={versions}
        version={version}
        handleVersionChange={handleVersionChange}
        reportSections={reportSections}
        selectedSections={selectedSections}
        handleSectionChange={handleSectionChange}
        isFormComplete={isFormComplete}
        handleCompileReport={handleCompileReport}
        fullSum={fullSum}
        handleFullSumChange={handleFullSumChange}
        companyInfo={companyInfo}
        onDragEnd={onDragEnd}
        selectedCompany={selectedCompany}
        sessions={sessions}
        selectedSession={selectedSession}
        setSelectedSession={setSelectedSession}
      />
      <div className="report-content">
        {isLoading && <MainLoader />}

        {error && <div className="error">{error}</div>}

        {report === 'No Tokens Left!' ? (
          <div className="no-tokens-message">
            <h2>No Tokens Left!</h2>
            <p>
              You have used all of your free tokens for this feature. To continue generating reports, please consider{' '}
              {/* <a href="/contact" className="contact-link">
                contacting us
              </a>{' '}*/}
              contacting us to sign up for a Pro account.
            </p>
          </div>
        ) : (
          report && (
            <div className="report-container">
              <div
                id="report-output"
                className="report-output"
                dangerouslySetInnerHTML={convertMarkdownToHTML(report, reportSections, selectedSections)}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default AIReport;
