import axios from 'axios';

const getVideoInfo = async (videoId, state) => {
  try {
    const response = await axios.get(`/api/${state.toUpperCase()}/video/get-video/${videoId}`);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const videoService = {
  getVideoInfo,
};

export default videoService;
