// votes.js

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPredictedVotes, getMemberPredictedVote } from '../features/bills/billSlice';
import ReactMarkdown from 'react-markdown';
import { useReactToPrint } from 'react-to-print';

const Votes = () => {
  const billId = 'HB164';
  const session = '89R';
  const version = 'FIL';
  const dispatch = useDispatch();
  const predictedVotes = useSelector((state) => state.bill.predictedVotes);
  const memberPrediction = useSelector((state) => state.bill.memberPrediction);

  const [sortConfig, setSortConfig] = useState({ key: 'memnum', direction: 'ascending' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const modalRef = useRef();

  useEffect(() => {
    dispatch(getPredictedVotes({ billId, session, version }));
  }, [dispatch, billId, session, version]);

  useEffect(() => {
    if (memberPrediction) {
      console.log('Member Prediction:', memberPrediction);
    }
  }, [memberPrediction]);

  const votes = predictedVotes || [];

  // Sorting function
  const sortedVotes = React.useMemo(() => {
    let sortableVotes = [...votes];
    if (sortConfig !== null) {
      sortableVotes.sort((a, b) => {
        let aKey = a[sortConfig.key];
        let bKey = b[sortConfig.key];

        // If sorting by 'yes', ensure numerical comparison
        if (sortConfig.key === 'yes') {
          aKey = Number(aKey);
          bKey = Number(bKey);
        } else {
          aKey = aKey.toString().toLowerCase();
          bKey = bKey.toString().toLowerCase();
        }

        if (aKey < bKey) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aKey > bKey) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableVotes;
  }, [votes, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? ' 🔼' : ' 🔽';
    }
    return '';
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMember(null);
  };

  const handleMemberClick = (memnum) => {
    setSelectedMember(memnum);
    dispatch(getMemberPredictedVote({ billId, session, version, memnum }));
    setIsModalOpen(true);
  };

  // Setup react-to-print
  const handlePrint = useReactToPrint({
    content: () => modalRef.current,
    documentTitle: `Member_Prediction_${selectedMember}`,
    onAfterPrint: () => console.log('Print Success'),
  });

  return (
    <div className="vote-table-container">
      <h1>Member Vote Predictions</h1>
      <table className="vote-table">
        <thead>
          <tr>
            <th style={{ width: '50px' }} onClick={() => requestSort('memnum')}>
              Member Number{getSortIndicator('memnum')}
            </th>
            <th style={{ width: '50px' }} onClick={() => requestSort('party')}>
              Party{getSortIndicator('party')}
            </th>
            <th onClick={() => requestSort('yes')} style={{ cursor: 'pointer' }}>
              Vote Breakdown{getSortIndicator('yes')}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedVotes.map((vote) => (
            <tr key={vote.memnum}>
              <td onClick={() => handleMemberClick(vote.memnum)} style={{ cursor: 'pointer', width: '50px' }}>
                {vote.memnum}
              </td>
              <td style={{ width: '50px' }}>{vote.party}</td>
              <td>
                <div className="bar-container">
                  <div className="bar yes-bar" style={{ width: `${vote.yes}%` }} title={`Yes: ${vote.yes}%`}></div>
                  <div className="bar no-bar" style={{ width: `${vote.no}%` }} title={`No: ${vote.no}%`}></div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && memberPrediction && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            {/* Close Button */}
            <button className="close-button" onClick={closeModal}>
              &times;
            </button>

            {/* Print Button */}
            <button className="print-button" onClick={handlePrint}>
              Print
            </button>

            {/* Yes/No bar */}
            <div className="modal-bar-container" ref={modalRef}>
              <div
                className="bar yes-bar"
                style={{ width: `${memberPrediction.yes}%` }}
                title={`Yes: ${memberPrediction.yes}%`}
              >
                {memberPrediction.yes > 15 && <span className="bar-label">{memberPrediction.yes}% Yes</span>}
              </div>
              <div
                className="bar no-bar"
                style={{ width: `${memberPrediction.no}%` }}
                title={`No: ${memberPrediction.no}%`}
              >
                {memberPrediction.no > 15 && <span className="bar-label">{memberPrediction.no}% No</span>}
              </div>
            </div>

            {/* Vote Explanation */}
            <div className="vote-explanation">
              <ReactMarkdown>{memberPrediction.prediction}</ReactMarkdown>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Votes;
