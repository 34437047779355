import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
// Store
import { useSelector, useDispatch } from 'react-redux';
import { getVideoInfo } from '../../features/video/videoSlice';
// Components
import { SharablePopup, TransciptContainer, CustomVideo } from '../../components/videoIndex';
import MainLoader from '../../components/Structures/MainLoader';
// Third party
import axios from 'axios';

const CreateClip = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { videoId, state } = useParams();
  const start = searchParams.get('start');
  const end = searchParams.get('end');
  const startPosition = searchParams.get('sp');
  const endPosition = searchParams.get('ep');

  const { video, isLoading, isError } = useSelector((state) => state.video);

  const [transcript, setTranscript] = useState([]);
  const [inputs, setInputs] = useState({ 'include-transcript': true });
  const [showPopup, setShowPopup] = useState(false);
  const [isLoadingClip, setIsLoadingClip] = useState(false);
  const [clipID, setClipID] = useState();

  useEffect(() => {
    if (videoId) {
      dispatch(getVideoInfo({ videoId, state }));
    }
  }, [videoId, state, dispatch]);

  useEffect(() => {
    if (video.transcript) {
      const startIdx = startPosition.split('-').map((e) => parseInt(e));
      const endIdx = endPosition.split('-').map((e) => parseInt(e));

      const aux = video.transcript.slice(startIdx[0], endIdx[0] + 1);
      const subTranscript = aux.map((p, i) => {
        if (aux.length === 1) return p.slice(startIdx[1], endIdx[1] + 1);
        if (i === 0) return p.slice(startIdx[1], p.length);
        if (i === aux.length - 1) return p.slice(0, endIdx[1] + 1);
        return p;
      });

      setTranscript(subTranscript);
    }
  }, [video.transcript, startPosition, endPosition]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(inputs);
    setShowPopup(true);
    setIsLoadingClip(true);
    try {
      const { data } = await axios.post(`/api/${state}/video/clip`, {
        VideoID: video.VideoID,
        Title: inputs['title'] || `${video.Committee} clip`,
        Description: inputs['description'] || null,
        StartTime: start,
        EndTime: end,
        StartPosition: startPosition,
        EndPosition: endPosition,
        IncludeTranscript: inputs['include-transcript'],
      });
      setClipID(data.id);
      setIsLoadingClip(false);
    } catch (error) {
      console.error(error);
      setIsLoadingClip(false);
      // Optionally handle error state
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeCheckbox = () => {
    setInputs((prev) => ({
      ...prev,
      'include-transcript': !prev['include-transcript'],
    }));
  };

  const handleClose = useCallback(() => {
    console.log('close');
    setShowPopup(false);
    navigate(`/${state}/video/${videoId}`);
  }, [navigate, state, videoId]);

  if (isLoading) return <MainLoader />;

  if (isError) return <div>Error</div>;

  return (
    <div className="w-full flex gap-5">
      {video && (
        <>
          <div className="w-[40%] h-full overflow-hidden">
            <div className="py-[15px] px-5 border border-[#E5E7EB] bg-[#F9FAFB] mb-4">
              <div className="border-b border-[#E5E7EB] mb-5 pb-2.5 ">
                <span className="font-playfair text-[28px] capitalize text-darkblue font-medium leading-8">
                  Create video clip: <strong>{inputs.title || video.Committee}</strong>
                </span>
              </div>
              <CustomVideo start={start} end={end} url={video.Link} />
              <form className="border-t-1 border-gray-300 pb-4 flex flex-col gap-4 mt-3 pt-3" onSubmit={handleSubmit}>
                <div>
                  <label className="font-playfair text-[22px] font-medium text-black mt-5 mb-4">Title </label>
                  <input
                    className="w-full border border-[#E5E7EB] bg-white p-[15px] text-base font-normal text-black placeholder:text-black/30 focus-within:outline-0"
                    name="title"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className="font-playfair text-[22px] font-medium text-black mt-5 mb-4">
                    Description (optional){' '}
                  </label>
                  <textarea
                    className="w-full border border-[#E5E7EB] bg-white p-[15px] text-base font-normal text-black placeholder:text-black/30 focus-within:outline-0 max-h-[100px]"
                    name="description"
                    onChange={handleChange}
                  />
                </div>
                <div className="flex gap-3 items-center">
                  <input
                    id="include-transcript"
                    className="w-5 h-5 border border-[#E5E7EB] opacity-50"
                    type="checkbox"
                    checked={inputs['include-transcript']}
                    name="includeTranscript"
                    onChange={handleChangeCheckbox}
                  />
                  <label className="text-sm font-normal text-black" htmlFor="include-transcript">
                    Include transcript{' '}
                  </label>
                </div>
                <div className="w-full flex">
                  <button
                    className="py-3 px-8 bg-[#83B2FA] text-base font-semibold text-[22px] text-white transition-all duration-500 hover:bg-darkblue"
                    type="submit"
                  >
                    CREATE CLIP
                  </button>
                </div>
              </form>
            </div>
          </div>
          <TransciptContainer transcript={transcript} miniTranscript />
        </>
      )}
      {showPopup && (
        <SharablePopup
          setPopup={setShowPopup}
          isLoading={isLoadingClip}
          uuid={clipID}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export default CreateClip;
