import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
// axios setup
import './axiosSetup';
// pages
import SearchPage from './pages/Search.js';
import BillPage from './pages/BillPage.js';
import LobbySearch from './pages/LobbySearch';
import Lobbyist from './pages/Lobbyist';
import Company from './pages/Company';
import Compare from './pages/Compare';
import ContactMe from './pages/ContactMe';
import Video from './pages/VideoPages/Video';
import Home from './pages/VideoPages/Home';
import CreateClip from './pages/VideoPages/CreateClip';
import Share from './pages/VideoPages/Share';
import Layout from './components/Layout/Layout.js';
import HomePage from './pages/HomePage.js';
import AIReport from './pages/AIReport.js';
import VoteTable from './pages/Votes.js';

// styles
import './styles/styles.css';

function App() {
  const { features } = useSelector((state) => state.user);

  return (
    <Router>
      <Routes>
        <Route path="/:state/" element={<Layout />}>
          {/* conditional routes */}
          <Route path="videos/:searchTerm?" element={<Home />} />
          <Route path="create-clip/:videoId" element={<CreateClip />} />
          <Route path="video/:videoId" element={<Video />} />

          <Route path="videos/share/:clipId" element={<Share />} />
          {/* Default routes */}
          <Route path="search/:searchTerm?" element={<SearchPage />} />
          <Route path="bill/:billId/:searchTerm?" element={<BillPage />} />
          <Route path="contactMe" element={<ContactMe />} />
          <Route path="compare/:billId" element={<Compare />} />
          <Route path="lobby/:searchTerm?" element={<LobbySearch />} />
          <Route path="lobbyist/:id" element={<Lobbyist />} />
          <Route path="company/:id" element={<Company />} />
          <Route path="ai-report" element={<AIReport />} />
          <Route path="votes" element={<VoteTable />} />
        </Route>
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Router>
  );
}

export default App;
