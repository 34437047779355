const convertMarkdownToHTML = (markdown, reportSections, selectedSections) => {
  // Define the headers as before
  const headers = [
    {
      value: 'companySentiment',
      title: 'Company Sentiment Towards the Bill',
      id: 'company-sentiment',
      className: 'report-section full-width',
    },
    { value: 'effectiveDate', title: 'Effective Date', id: 'effective-date', className: 'report-section full-width' },
    { value: 'areaOfEffect', title: 'Area of Effect', id: 'area-of-effect', className: 'report-section full-width' },
    {
      value: 'beneficiaries',
      title: 'Who Benefits',
      id: 'who-benefits',
      className: 'report-section full-width',
    },
    {
      value: 'negativelyAffected',
      title: 'Who is Negatively Impacted',
      id: 'who-is-negatively-impacted',
      className: 'report-section full-width',
    },
    { value: 'summary', title: 'Summary', id: 'summary-of-bill', className: 'report-section full-width' },
    { value: 'keyProvisions', title: 'Key Provisions', id: 'key-provisions', className: 'report-section full-width' },
    {
      value: 'financialImplications',
      title: 'Financial Implications',
      id: 'financial-implications',
      className: 'report-section full-width',
    },
    {
      value: 'regulatoryImpact',
      title: 'Regulatory Impact',
      id: 'regulatory-impact',
      className: 'report-section full-width',
    },
    {
      value: 'timelineAndProceduralStatus',
      title: 'Timeline and Procedural Status',
      id: 'timeline-and-procedural-status',
      className: 'report-section full-width',
    },
    {
      value: 'potentialAmendments',
      title: 'Potential Amendments',
      id: 'potential-amendments',
      className: 'report-section full-width',
    },
    {
      value: 'implementationChallenges',
      title: 'Implementation Challenges',
      id: 'implementation-challenges',
      className: 'report-section full-width',
    },
    {
      value: 'legalConsiderations',
      title: 'Legal Considerations',
      id: 'legal-considerations',
      className: 'report-section full-width',
    },
    {
      value: 'publicOpinionAndMediaCoverage',
      title: 'Public Opinion and Media Coverage',
      id: 'public-opinion-and-media-coverage',
      className: 'report-section full-width',
    },
    {
      value: 'companyImpactAnalysis',
      title: 'Company Impact Analysis',
      id: 'company-impact-analysis',
      className: 'report-section full-width',
    },
    {
      value: 'recommendedActions',
      title: 'Recommended Actions',
      id: 'recommended-actions',
      className: 'report-section full-width',
    },
    {
      value: 'riskAssessment',
      title: 'Risk Assessment',
      id: 'risk-assessment',
      className: 'report-section full-width',
    },
    {
      value: 'complianceRequirements',
      title: 'Compliance Requirements',
      id: 'compliance-requirements',
      className: 'report-section full-width',
    },
    {
      value: 'stakeholderMap',
      title: 'Stakeholder Map',
      id: 'stakeholder-map',
      className: 'report-section full-width',
    },
    {
      value: 'technicalAnalysis',
      title: 'Technical Analysis',
      id: 'technical-analysis',
      className: 'report-section full-width',
    },
    {
      value: 'environmentalImpact',
      title: 'Environmental Impact',
      id: 'environmental-impact',
      className: 'report-section full-width',
    },
    { value: 'futureOutlook', title: 'Future Outlook', id: 'future-outlook', className: 'report-section full-width' },
  ];

  const cleanedLines = markdown
    .split('\n')
    .filter((line) => !line.trim().startsWith('[0]'))
    .map((line) => line.trim());

  let sections = [];
  let currentSection = null;

  cleanedLines.forEach((line) => {
    const headerMatch = line.match(/^###\s*(\d+)?\.?\s*(.+)/);
    if (headerMatch) {
      // Close previous section
      if (currentSection) {
        if (currentSection.content.includes('<ul class="section-list">') && !currentSection.content.endsWith('</ul>')) {
          currentSection.content += '</ul>';
        }
        sections.push(currentSection);
      }
      const headerTitle = headerMatch[2].trim();
      const foundHeader = headers.find((h) => h.title === headerTitle);
      currentSection = {
        title: headerTitle,
        value: foundHeader ? foundHeader.value : headerTitle.toLowerCase().replace(/\s+/g, '-'),
        id: foundHeader ? foundHeader.id : headerTitle.toLowerCase().replace(/\s+/g, '-'),
        className: foundHeader ? foundHeader.className : 'report-section full-width',
        content: `<h2 class="section-title">${headerTitle}</h2>`,
      };
    } else if (currentSection) {
      let formattedLine = line
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
        .replace(/\*(.*?)\*/g, '<em>$1</em>')
        .replace(/\[([^\[]+)\]\(([^\)]+)\)/g, '<a href="$2">$1</a>');

      if (formattedLine.startsWith('- ')) {
        formattedLine = `<li>${formattedLine.slice(2)}</li>`;
        if (!currentSection.content.includes('<ul class="section-list">')) {
          currentSection.content += '<ul class="section-list">';
        }
        currentSection.content += formattedLine;
      } else {
        if (currentSection.content.includes('<ul class="section-list">') && !formattedLine.startsWith('<li>')) {
          currentSection.content += '</ul>';
        }
        formattedLine = `<p class="section-paragraph">${formattedLine}</p>`;
        currentSection.content += formattedLine;
      }
    }
  });

  // Close last section
  if (currentSection) {
    if (currentSection.content.includes('<ul class="section-list">') && !currentSection.content.endsWith('</ul>')) {
      currentSection.content += '</ul>';
    }
    sections.push(currentSection);
  }

  // Filter and sort sections by the order in reportSections and selectedSections
  const selectedOrderedValues = reportSections.map((sec) => sec.value).filter((val) => selectedSections.includes(val));

  const orderedSections = selectedOrderedValues.map((val) => sections.find((sec) => sec.value === val)).filter(Boolean);
  let htmlContent = '<div class="report-output">';

  orderedSections.forEach((section) => {
    // Add contentEditable and suppressContentEditableWarning here
    htmlContent += `
      <div 
        id="${section.id}" 
        class="${section.className}" 
        style="position: relative;" 
        contenteditable="true"
        suppressContentEditableWarning="true"
      >
        ${section.content}
      </div>
    `;
  });

  htmlContent += `
    <button class="print-button" onclick="window.print()">Print Report</button>
  </div>`;

  return { __html: htmlContent };
};

if (typeof window !== 'undefined') {
  window.onbeforeprint = function () {
    // No special transformations needed.
    // Just ensure the report container doesn't overflow horizontally.
    // If desired, you could set a max width here via inline style:
    // const reportContainer = document.querySelector('.report-container');
    // reportContainer.style.width = '8.5in'; // U.S. Letter width, for example.
    // reportContainer.style.margin = '0 auto';
  };

  window.onafterprint = function () {
    // Reset any inline styles if you set them before printing.
    const reportContainer = document.querySelector('.report-output');
    if (reportContainer) {
      reportContainer.style.width = '';
      reportContainer.style.margin = '';
      reportContainer.style.position = '';
      reportContainer.style.transform = '';
      reportContainer.style.left = '';
      reportContainer.style.top = '';
    }
  };
}

export default convertMarkdownToHTML;
