import React from 'react';

const TabsSwitch = ({ options, isActive, onSwitch }) => {
  return (
    <ul className="tabs-nav max-w-[calc(50%-12px)] gap-4 flex !mb-5">
      {options?.map((option, index) => (
        <li key={index} className={isActive === option.value ? 'active' : ''} onClick={() => onSwitch(option.value)}>
          <div className="cursor-pointer">{option.label}</div>
        </li>
      ))}
    </ul>
  );
};

export default TabsSwitch;
