import React, { useState } from 'react';

const NewSearchBar = ({ data, fields, label, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showFilteredData, setShowFilteredData] = useState(false);

  const getValueFromItem = (item) => {
    if (typeof item === 'string') {
      return item;
    } else if (typeof item === 'object' && fields) {
      return fields.map((field) => item[field]).join(' ');
    }
    return '';
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    setShowFilteredData(value.length > 0);
  };

  const filteredData =
    searchTerm.length > 0
      ? data.filter((item) => {
          const itemValue = getValueFromItem(item).toLowerCase();
          return itemValue.includes(searchTerm);
        })
      : [];

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const topResult = filteredData.length > 0 ? filteredData[0] : null;
      if (topResult) {
        const selectedValue = getValueFromItem(topResult);
        setSearchTerm(selectedValue);
        setShowFilteredData(false);
        onSelect(topResult);
      }
    }
  };

  const handleSelect = (item) => {
    const selectedValue = getValueFromItem(item);
    setSearchTerm(selectedValue);
    setShowFilteredData(false);
    onSelect(item);
  };

  const handleBlur = () => {
    // Delay hiding so that click events can register
    setTimeout(() => {
      setShowFilteredData(false);
    }, 150);
  };

  return (
    <div className="company-search" style={{ position: 'relative', width: '100%' }}>
      <label>{label} Search:</label>
      <input
        type="text"
        placeholder={`Search ${label}...`}
        value={searchTerm}
        onChange={handleSearch}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        style={{ width: '100%' }}
      />
      {showFilteredData && filteredData.length > 0 && (
        <div className="dataResult">
          <ul>
            {filteredData.slice(0, 4).map((item, index) => (
              <li key={index} onClick={() => handleSelect(item)}>
                {getValueFromItem(item)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default NewSearchBar;
