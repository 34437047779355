import React, { useEffect, useState, useRef, useCallback } from 'react';
// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// store
import { getMatches, clearBills } from '../features/bills/billSlice';
import { createSelector } from 'reselect';
import { setSession } from '../features/user/userSlice';
// components
import Dropdown from '../components/Structures/Dropdown';
import Table from '../components/Structures/Table';
import ThreeArrowsSVG from '../components/SVGs/ThreeArrowsSVG';
import tdot from '../assets/images/tdot.jpg';

// Define your memoized selectors if they are not already defined elsewhere
const selectBills = createSelector(
  (state) => state.bill.bills,
  (bills) => bills
);

const selectTracks = createSelector(
  (state) => state.user.tracks,
  (tracks) => tracks
);

function SearchPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mostRecentQuery = localStorage.getItem('mostRecentQuery');

  const { session, sessions } = useSelector((state) => state.user);
  const bills = useSelector(selectBills);
  const tracks = useSelector(selectTracks);

  const [query, setQuery] = useState(mostRecentQuery || '');
  const [results, setResults] = useState([[]]);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowingFluffedResults, setIsShowingFluffedResults] = useState(false);
  const [numResults, setNumResults] = useState('20');
  const [minPercentage, setMinPercentage] = useState(80);

  const [onlyStrongMatches, setOnlyStrongMatches] = useState(true);

  const inputRef = useRef(null);

  const handleBillClick = (billId) => {
    navigate(`../bill/${billId}?session=${session}`);
  };

  const handlePrintResults = () => {
    // Create a new window or use an iframe to format the print content
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write(
      '<html><head><title>Print Search Results</title><style>body { font-family: Arial; } table { width: 100%; border-collapse: collapse; } th, td { padding: 8px; text-align: left; border-bottom: 1px solid #ddd; } th { background-color: #f2f2f2; } .evenRow { background-color: #f9f9f9; } .oddRow { background-color: #e6e6e6; }</style></head><body>'
    );

    // Header row
    printWindow.document.write('<table>');
    printWindow.document.write('<thead>');
    printWindow.document.write('<tr>');
    printWindow.document.write('<th>Number</th>');
    printWindow.document.write('<th>Author</th>');
    printWindow.document.write('<th>Title</th>');
    printWindow.document.write('</tr>');
    printWindow.document.write('</thead>');
    printWindow.document.write('<tbody>');

    // Assuming `results` is an array containing objects with properties `id`, `metadata.author`, and `metadata.title`
    results.forEach((result, index) => {
      const rowClass = index % 2 === 0 ? 'evenRow' : 'oddRow';
      printWindow.document.write(
        `<tr class="${rowClass}"><td>${result.id}</td><td>${result.metadata.author}</td><td>${result.metadata.title}</td></tr>`
      ); // Customize based on your result object structure
    });

    printWindow.document.write('</tbody>');
    printWindow.document.write('</table>');
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  useEffect(() => {
    const sortedBills = [...bills].sort((a, b) => b.score - a.score);

    let filteredBills = sortedBills;

    if (onlyStrongMatches) {
      const percentageThreshold = 0.37; // Set the threshold for strong matches
      filteredBills = sortedBills.filter((bill) => bill.score >= percentageThreshold);
    }

    // Determine if showing fluffed results
    const showingFluffedResults = filteredBills.length < 3 && sortedBills.length > 0;

    // Update state based on the above logic
    setIsShowingFluffedResults(showingFluffedResults);

    // Determine the final bills to show based on whether we're fluffing the results
    const finalToShow = showingFluffedResults ? sortedBills.slice(0, 5) : filteredBills;

    setResults(finalToShow); // Assuming you use this to render your components
  }, [bills, minPercentage, numResults, session, onlyStrongMatches]);

  useEffect(() => {
    // Clear the search results and query when the session changes
    setResults([]);
    setQuery("");
    localStorage.removeItem("mostRecentQuery");

    // Clear the bills from the Redux store
    dispatch(clearBills());
  }, [session]);

  const handleCheckboxChange = (e) => {
    setOnlyStrongMatches(e.target.checked);
  };

  const performSearch = useCallback(async () => {
    if (query.trim() === '') {
      return;
    }
    localStorage.setItem('mostRecentQuery', query);
    setIsLoading(true);
    try {
      await dispatch(
        getMatches({
          query,
          numResults: numResults || 20,
          minPercentage,
          session,
        })
      );
      setIsLoading(false);
    } catch (error) {
      console.error('Failed to fetch matches: ', error);
      setIsLoading(false);
    }
  }, [dispatch, query, numResults, minPercentage, session]);

  const handleSearch = (e) => {
    if (e) e.preventDefault();
    performSearch();
  };

  return (
    <>
      <div className="bg-white border border-[#E5E7EB] 2xl:p-5 xl:p-5 p-5 w-full mb-[20px]">
        <div className="border-b border-[#E5E7EB] mb-3.5 pb-3.5 flex flex-col md:flex-row max-md:gap-4 items-center justify-between">
          <h2 id="Office-Information" className="font-playfair text-[28px] text-darkblue font-medium leading-8">
            AI Search and Analysis Tool
          </h2>
        </div>
        <div className="flex items-start gap-[15px]">
          <div className="hidden min-[1161px]:flex items-center gap-3 text-base font-medium text-black mt-2">
            Search
            <ThreeArrowsSVG />
          </div>
          <div className="flex flex-col lg:flex-row w-full items-start gap-[15px]">
            <input
              type="text"
              className="border border-[#E5E7EB] bg-white text-black text-base font-normal block w-full py-2 px-3.5 focus:outline-none placeholder:text-black/30"
              ref={inputRef} // Attach the ref to the input
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search for a bill..."
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
            <div className="flex flex-col gap-[15px] w-full">
              <Dropdown
                id="Numberofitem"
                value={numResults}
                options={['20', '30', '40', '50']}
                title="# of Matches"
                label={false}
                onChange={(e) => setNumResults(e.target.value)}
              />
              {/*<div className="flex items-center gap-2.5">
                <input
                  type="checkbox"
                  id="Strongest"
                  checked={onlyStrongMatches}
                  onChange={handleCheckboxChange}
                />
                <label
                  htmlFor="Strongest"
                  className="text-sm font-normal text-black"
                >
                  Only Strongest Matches
                </label>
            </div>*/}
            </div>
            <Dropdown
              id="session-id"
              options={sessions}
              title="Session"
              label={false}
              value={session}
              onChange={(e) => dispatch(setSession(e.target.value))}
              disabled={sessions.length <= 1}
            />
            <div className="flex items-center gap-4">
              <button
                type="button"
                className="py-2 px-6 bg-[#ECEFF4] text-base font-normal text-black transition-all duration-300 hover:bg-darkblue hover:text-white"
                onClick={handleSearch}
              >
                Search
              </button>
              <button
                type="button"
                className="py-2 px-12 bg-[#83B2FA] text-base font-normal text-white transition-all duration-300 hover:bg-lightblue-hover"
                onClick={handlePrintResults}
              >
                Print
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#F9FAFB] border border-[#E5E7EB] px-5 py-[15px] w-full mb-[20px]">
        <div className="border-b border-[#E5E7EB] mb-5 pb-2.5">
          <h2 id="Office-Information" className="font-playfair text-[28px] text-darkblue font-medium leading-8">
            Bill Results
          </h2>
        </div>
        <div className="">
          <div className="block relative overflow-x-auto border border-[#E5E7EB] z-0">
            <Table
              headers={['Bill Number', 'Track', 'Author', 'Title']}
              rows={results?.map((item) => [
                <span className="text-[#0000F1] underline cursor-pointer" onClick={() => handleBillClick(item.id)}>
                  {item.id}
                </span>,
                tracks[item.id] && <img alt="tdot" src={tdot} />,
                item.metadata?.author,
                item.metadata?.title,
              ])}
              loading={isLoading}
            />
          </div>
        </div>
        {isShowingFluffedResults && (
          <div className="insufficient">
            There were not enough matches that met your match threshold, but we have provided the top 5 results to your
            query. They may or may not be quality matches. Please refine your query.
          </div>
        )}
        <div className="insufficient">
          The responses on this website are generated by artificial intelligence tools and do not reflect the views or
          opinions of our company. Please verify information independently.
        </div>
      </div>
    </>
  );
}

export default SearchPage;
