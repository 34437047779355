import React, { useEffect, useState } from 'react';
// Third party
import axios from 'axios';
// Components
import { PaginationNav, SearchTable } from '../videoIndex';
import Table from '../Structures/Table';
import { Link, useLocation, useParams } from 'react-router-dom';
// Icons
import { IoCalendarClearOutline } from 'react-icons/io5';
import { TbClockPlay } from 'react-icons/tb';
// store
import { useDispatch, useSelector } from 'react-redux';
// utils
import { formatTime } from '../../utils';
import { setFilterCommittee } from '../../features/video/videoSlice';

const HomeTable = ({ searchRes, loading, setLoading }) => {
  const dispatch = useDispatch();
  const { state } = useParams();
  const [videoList, setVideoList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const committeeQuery = queryParams.get('committee');

  const headersVideo = ['Date', 'Title', 'Chamber', 'Legislature', 'Duration'];

  const rowsVideo = videoList.map((videoInfo) => [
    <span className="flex items-center gap-2">
      <IoCalendarClearOutline className="text-gray-400 w-4 h-4" />
      {new Date(videoInfo.Date).toLocaleDateString('en-US', { timeZone: 'UTC' })}
    </span>,
    <Link key={videoInfo.VideoID} to={`/${state}/video/${videoInfo.VideoID}`}>
      {videoInfo.Committee}
    </Link>,
    videoInfo.Chamber === 'H' ? 'House' : 'Senate',
    <span className="bg-gray-100 px-2 py-1">{videoInfo.Legislature || 'no legislature'}</span>,
    <span className="flex items-center gap-1">
      <TbClockPlay className="text-gray-400 w-5 h-5" />
      {formatTime(videoInfo.Duration)}
    </span>,
  ]);

  const filters = useSelector((state) => state.video.filter);

  useEffect(() => {
    if (!searchRes) getVideoList();
  }, [page, searchRes, filters]);

  useEffect(() => {
    if (committeeQuery) dispatch(setFilterCommittee(committeeQuery));
  }, []);

  const getVideoList = async () => {
    try {
      setLoading(true);

      let api_url = `/api/video?page=${page}&pageSize=${20}`;

      if (filters?.date) {
        api_url = api_url + `&month=${filters?.date.getMonth() + 1}&year=${filters?.date.getFullYear()}`;
      }

      if (filters?.chamber) api_url += `&chamber=${filters?.chamber}`;

      if (filters?.committee || committeeQuery) api_url += `&committee=${filters?.committee || committeeQuery}`;

      if (filters?.legislature) api_url += `&legislature=${filters?.legislature}`;

      const response = await axios.get(api_url);
      setVideoList(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col gap-3 justify-between relative">
      <div className="rounded-xl text-gray-500 ">
        {!searchRes ? (
          <Table headers={headersVideo} rows={rowsVideo} loading={loading} />
        ) : (
          <SearchTable searchList={searchRes || []} />
        )}
      </div>
      {searchRes?.length < 1 && (
        <span className="text-xl mt-5 text-gray-500">I'm sorry, we didn't find any video...</span>
      )}
      {!loading && !searchRes && (
        <div className="w-full flex justify-end">
          <PaginationNav selected={page} setSelected={setPage} totalPages={totalPages} />
        </div>
      )}
    </div>
  );
};

export default HomeTable;
